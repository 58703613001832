





















































import {Component, Vue} from "vue-property-decorator";
import MenuStore from "@/Store/Modules/MenuStore";
import {getModule} from "vuex-module-decorators";
import siteAPI from "@/Api/Site/SiteApi";
import UserStore from "@/Store/Modules/UserStore";
import SiteResponse from "@/Api/Site/Response/SiteResponse";
import SiteStore from "@/Store/Modules/SiteStore";

@Component
export default class Index extends Vue {
    menuStore: MenuStore = getModule(MenuStore);
    userStore: UserStore = getModule(UserStore);
    siteStore: SiteStore = getModule(SiteStore);
    site: SiteResponse = {
        created_at: "",
        has_certificate: false,
        id: "",
        php_version: "",
        server: undefined,
        status: 0,
        status_name: "",
        user: "",
        website: "",
        website_path: ""

    };
    loading: boolean = true;
    siteId: string|null = null;
    path: string = '/sites/' + this.$route.params['id'];
    items: any = [
        { text: 'PHP', icon: 'mdi-language-php', to: this.path + '/php' },
        { text: 'Certificates', icon: 'mdi-lock', to: this.path + '/php' },
        { text: 'Settings', icon: 'mdi-cog', to: this.path + '/settings' },
    ];

    async mounted() {
        this.siteId = this.$route.params['id'];

        window.Echo.private('site.' + this.siteId).listen('.server.install.package',() => {
            this.getServer();
        }).listen('.site.deleted',() => {
            this.$router.push('/servers/' + this.site.server?.id);
        });

        await this.getServer();
    }

    getServer() {
        siteAPI.getSite(this.siteId as string).then((response) => {
            this.site = response.data as SiteResponse;
            this.siteStore.setSite(this.site);
        }).catch((result) => {
            if (result.response && result.response.status === 404) {
                this.$router.push('/servers');
            }
        });
    }

    beforeDestroy() {
        window.Echo.leave('site.' + this.siteId);
        this.menuStore.clear();
    }
}
