



































































import {Component, Vue} from "vue-property-decorator";
import MenuStore from "@/Store/Modules/MenuStore";
import {getModule} from "vuex-module-decorators";
import serverApi from "@/Api/Server/ServerApi";
import ServerResponse from "@/Api/Server/Response/ServerResponse";
import UserStore from "@/Store/Modules/UserStore";
import ServerStore from "@/Store/Modules/ServerStore";

@Component
export default class Index extends Vue {
    menuStore: MenuStore = getModule(MenuStore);
    userStore: UserStore = getModule(UserStore);
    serverStore: ServerStore = getModule(ServerStore);
    server: ServerResponse = {
        created_at: "",
        id: "",
        ip_address: "",
        name: "",
        port: 0,
        server_os: "",
        status: 0,
        default_php: null,
        uptime: "",
        install_state: null
    };
    loading: boolean = true;
    serverId: string|null = null;
    path: string = '/servers/' + this.$route.params['id'];
    items: any = [
        { text: 'Websites', icon: 'mdi-sitemap', to: this.path },
        { text: 'PHP', icon: 'mdi-language-php', to: this.path + '/php' },
        { text: 'Database', icon: 'mdi-database', to: this.path + '/database' },
        { text: 'Cronjobs', icon: 'mdi-alarm', to: this.path + '/cronjobs' },
        { text: 'SSH keys', icon: 'mdi-key', to: this.path + '/ssh-keys' },
        { text: 'Firewall', icon: 'mdi-cloud-lock', to: this.path + '/firewall' },
        { text: 'Docker', icon: 'mdi-docker', to: this.path + '/docker' },
        { text: 'Supervisor', icon: 'mdi-timer-cog', to: this.path + '/supervisor' },
        { text: 'Services', icon: 'mdi-application-cog', to: this.path + '/services' },
        { text: 'Settings', icon: 'mdi-cog', to: this.path + '/settings' },
    ];

    mounted() {
        this.serverId = this.$route.params['id'];

        window.Echo.private('server.' + this.serverId)
            .listen('.server.install.package',(response: ServerResponse) => {
                this.serverStore.setServer(response);
            })
            .listen('.server.updated',(response: ServerResponse) => {
                this.serverStore.setServer(response);
            });

        this.getServer();
    }

    getServer() {
        serverApi.getServer(this.serverId as string).then((response) => {
            this.server = response.data as ServerResponse;
            this.serverStore.setServer(this.server);
            this.menuStore.addTitle(this.server.name);
        }).catch((result) => {
            if (result.response && result.response.status === 404) {
                this.$router.push('/servers');
            }
        });
    }

    beforeDestroy() {
        window.Echo.leave('server.' + this.serverId);
        this.menuStore.clear();
    }
}
